.loadingmain {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100dvh;
  }
  
  .loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
  }
  